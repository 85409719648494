$blue: rgb(0, 113, 173);
$light-blue: rgb(64, 193, 255);

$white: rgb(255, 255, 255);
$light-grey: #f2f6f7;
$grey: rgb(236, 236, 236);
$shadow-black: rgba(0, 0, 0, 0.16);
$dark-grey: rgb(39, 41, 54);

$red: #e30021;
