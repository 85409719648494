@import '../../styles/colors';

.wrapper {
	padding: 8px;
	width: 100vw;
}

.header {
	display: flex;
	align-items: center;
	gap: 32px;
	width: 100%;
	padding: 16px 24px;
	border-bottom: 1px solid $grey;
}

.backLink {
	position: absolute;
}

.title {
	font-size: 1.6rem;
	text-align: center;
	flex: 1;
	margin: 0;
	font-weight: 500;
	color: $dark-grey;
}

.listWrapper {
	display: flex;
	justify-content: center;
	margin: 0 -8px;
}

.pictureList {
	display: flex;
	flex-wrap: nowrap;
	overflow: scroll;
	gap: 8px;
	padding: 0 8px;
}

.picture {
	object-fit: contain;
	min-height: 300px;
	max-height: 500px;
	cursor: pointer;
	height: 35vh;
	border-radius: 4px;
}

.report {
	padding: 16px;
	border-radius: 4px;
	background-color: $light-grey;
	max-width: 700px;
	margin: 16px auto;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	cursor: pointer;
	background-color: rgba(black, 0.5);
}

.legend {
	text-align: center;
	margin-bottom: 0;
}

.modal {
	position: absolute;
	top: 50%; /* position the top  edge of the element at the middle of the parent */
	left: 50%; /* position the left edge of the element at the middle of the parent */
	transform: translate(-50%, -50%);
	width: 95%;
	max-width: 1200px;
	max-height: 1000px;
	height: 95%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	border-radius: 4px;
	outline: none;
	cursor: default;
	padding: 20px;
}

.fullScreenPicture {
	display: block;
	object-fit: contain;
	width: 100%;
	height: 100%;
}

.infoLine {
	display: flex;
	margin: 16px 0;
	flex-flow: row nowrap;
	justify-content: space-between;
	.location {
		color: $dark-grey;
		font-weight: 400;
	}
	.type {
		background-color: $light-blue;
		color: white;
		padding: 4px 8px;
		font-weight: 500;
		border-radius: 4px;
	}
}

.footer {
	text-align: center;
	margin: 16px;
}
