@import '../../styles/colors';

.button {
	background-color: $blue;
	border: none;
	border-radius: 40px;
	font-size: 1rem;
	padding: 20px 40px;
	font-weight: 600;
	color: $white;
	transition: all 0.15s ease-in-out 0s;
	cursor: pointer;
	&:hover {
		background-color: $light-blue;
	}
	&:disabled {
		cursor: not-allowed;
		background-color: $grey;
	}
}
