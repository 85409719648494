@import '../../styles/colors';

.wrapper {
	display: flex;
	flex-direction: column;
	padding: 24px;
	align-items: center;
	gap: 50px;
	height: 100vh;
	width: 100vw;
}
.logo {
	padding: 64px;
	min-width: 200px;
	width: 100%;
	max-width: 400px;
	object-fit: contain;
}

.btnSection {
	display: flex;
	gap: 16px;
	justify-content: center;
	position: relative;
	align-items: center;
	flex-flow: column wrap;
}

.imageInput {
	display: flex;
	gap: 8px;
	font-size: 1rem;
	align-items: center;
	line-height: normal;
	background-color: $blue;
	border: none;
	border-radius: 40px;
	padding: 20px 40px;
	color: $white;
	transition: all 0.15s ease-in-out 0s;
	cursor: pointer;
	&:hover {
		background-color: $light-blue;
	}
}

.error {
	color: $white;
	background-color: rgba($red, 0.6);
	font-size: 0.9rem;
	text-align: center;
	padding: 8px 16px;
	margin: 0;
	position: absolute;
	top: -80px;
	width: fit-content;
	border-radius: 4px;
}
