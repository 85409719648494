@import-normalize;
@import-sanitize;

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf');
}
* {
	font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
}
body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
